:root {
  --lighter-grey: #919294;
  --dark-grey: #595959;
}

.tos-container {
  margin-bottom: 5.8rem;
  padding: 2rem 2rem 0;
}

.para-heading {
  color: var(--lighter-grey);
  margin-bottom: 1rem;
}

.para-subheading{
  color: var(--lighter-grey);
  margin-bottom: 1rem;
  font-size: 1.2em;
}

.tos-container li{
  margin: 1.5rem 0;
}

.bold{
  font-weight: 700;
}

.date-for {
  margin: 2rem 0;
  color: var(--dark-grey);
}

.in-short{
  color: var(--dark-grey);
}

.in-short strong{
  color: var(--dark-grey);
}

.paragraph {
  font-size: 0.9rem;
  color: var(--lighter-grey);
}

.paragraph > strong {
  color: var(--dark-grey);
}

.underline{
    text-decoration: underline;
}

.black{
    color: var(--dark-grey);
}

.lightGray{
    color: var(--lighter-grey);
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .tos-container {
  }

  .para-heading {
  }

  .date {
  }
}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .tos-container {
    padding: 1rem 1rem 0;
  }

  .para-heading {
    font-size: 1.1em;
  }

  .date {
    font-size: 0.9em;
  }

  .paragraph {
    font-size: 0.7rem;
  }
}
