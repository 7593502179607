.bannersContainer{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    background-color: black;
}

.bannersContainer .subContainer{
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.bannersContainer .subContainer img{
    height: auto;
    max-width: 100%;
    opacity: 0.8;
}

.bannersContainer .subContainer h1{
    font-size: 1.2rem;
    color:white;
    position: absolute;
    font-weight: 400;
    text-align: center;
    align-self: center;
    margin: auto;
}

.headingTop{
    top: 1.5rem 
}

.headingBottom{
    bottom: 1.5rem
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
    .bannersContainer{
        display: none;
    }
}