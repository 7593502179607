.get-in-touch-container{
    width: 100%;
    background-color:#F6F4E8;
    padding: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.git-button--wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .git-cta {
    background-color : #063B52;
    padding: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    cursor: pointer;
    border-radius: 70px;
   }
   
   .git-text {
    color: #000000;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 1rem;
}

.git-cta-text{
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: bold;
   }

   .git-cta-sub-text{
    color: #B69E0B;
    margin-top: 1rem;
    font-size: 1.5rem;
   }

   /* --------------------Mobile and Tablets-------------------- */
@media only screen and (max-width: 680px) {

    .git-cta {
        padding-left: 3rem;
        padding-right: 3rem;
        
       }

    .git-text {

        font-size: 1rem;
        font-weight: bold;
    }
    
    .git-cta-text{

        font-size: 1rem;
       }
    
       .git-cta-sub-text{
        font-size: 1rem;
       }

}
