.refund-policy-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 2rem 5rem;
}

.update-date {
  color: #666;
}

.parties,
.background,
.subscription-bookings {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {

  .refund-policy-container {
      padding: 1rem;
  }

}