.testimonial-container{
    max-width: 100%;
    padding: 1rem 5rem;
    padding-bottom: 2rem;
}

.testimonial-heading{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.testimonials{

    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.testimonial-heading-text{
font-size: 1.5rem;
}

.testimonial-card{
    width: 80%;
    margin-top: 0rem;
    margin: 1rem;
    

}

.left-quote{
    position: relative;
    top: -0.4rem;
    color: #063B52;
}

.right-quote{
    position: relative;
    bottom: -0.4rem;
    color: #063B52;
}

.testimonial-card-text{
    text-align: center;
    font-size: 1.2rem;

}

.slick-slide {
    margin: 0 20px;
  }

  
   /* --------------------Mobile and Tablets-------------------- */
@media only screen and (max-width: 680px) {
    .testimonial-container{
        max-width: 100%;
        padding: 1rem ;
        padding-bottom: 2rem;
    }

    .testimonials{

        flex-direction: column;
    }


}