:root {
  --border-dark-grey: #484848;
}

/* PageShell */
.blogs-category-page {
  letter-spacing: 0;
  font-family: var(--basier-circle-font);
  /* background-image: url('../../assets/background/background1-2.png'); */
  background-size: cover;
  display: flex;
  justify-content: center;
}

/* PageContainer */
.blogs-category-page-container {
  background-color: white;
  padding: 1rem;
}

.blogs-category-main-grid > a {
  color: black;
  text-decoration: none;
}

/* PageTag */
.blogs-category-page-container > h3 {
  background-color: black;
  color: white;
  font-weight: 300;
  border-radius: 20px;
  margin: 1rem 0;
  text-align: left;
  font-size: 0.9em;
  padding: 2px 9px;
  max-width: fit-content;
}

/* MAIN GRID */
.blogs-category-main-grid {
  margin-top: 1rem;
}

/* Title and back link */

.blogs-category-page-container > div {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.blogs-category-page-container > div > h1 {
  font-size: 1.5em;
  font-weight: 300;
}

.blogs-category-page-container > div > a {
  color: #4d4d4d;
  text-decoration: none;
}

/* MAINCARD */

.blogs-category-page-main-card {
  border-radius: 20px;
  border: 0.1px solid var(--border-dark-grey);
  padding: 0.5rem 1rem;
  max-width: fit-content;
}

/* PLACEHOLDERIMG */
.blogs-category-page-main-card > div {
  padding: 5.5rem 9rem;
  background-color: var(--placeholder-box-color);
  border-radius: 10px;
}

/* TEXT */
.blogs-category-page-main-card > section {
  padding-top: 1rem;
}

/* AUTHORNAME AND DATE */
.blogs-category-page-main-card > section > h4 {
  font-size: 0.6em;
  font-weight: 500;
}

/* TITLE */
.blogs-category-page-main-card > section > h1 {
  font-weight: 300;
  font-size: 1.5em;
  max-width: 20ch;
}

/* DESCRIPTION */
.blogs-category-page-main-card > section > summary {
  max-width: 35ch;
  font-size: 0.9em;
  padding-top: 1rem;
}

/* NEWSLETTER  --depriciated */

.blogs-category-newsletter-card {
  border: 0.1px solid var(--border-dark-grey);
  max-width: fit-content;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}

.blogs-category-newsletter-card > h3 {
  font-size: 1em;
  font-weight: 300;
}

/* ALL BLOGS */
.blogs-category-page-container > h1 {
  grid-area: allBlogs;
  font-weight: 400;
  font-size: 1.5em;
  max-width: 20ch;
  padding-top: 2rem;
}

.blogs-category-all {
  display: flex;
  flex-direction: column;
}

/* GRID SETUP */
.blogs-category-newsletter-card {
  grid-area: newsletter;
}

.blogs-category-page-main-card {
  grid-area: mainCard;
}

.blogs-category-list {
  grid-area: list;
}

.blogs-category-main-grid > h1 {
  grid-area: allBlogs;
  align-self: flex-end;
}

.blogs-category-main-grid {
  display: grid;
  grid-template-areas: 'mainCard list';
  gap: 1rem;
  align-items: end;
}

/* List of the Blogs in the specific category */
.blogs-category-list .blogs-list-card > div {
}

.blogs-category-list .blogs-list-card > section > h1 {
  max-width: 15ch;
}

/* --------------------Laptop-------------------- */
@media only screen and (max-width: 1024px) {
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {

  .blogs-category-page-container > h1 {
  }

  .blogs-category-page-container > div > h1 {
    display: none;
  }

  .blogs-category-main-grid {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .blogs-category-all .blogs-list-card > section > h1 {
    max-width: 15ch;
  }
}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .blogs-category-all .blogs-list-card {
    gap: 0.5rem;
  }

  .blogs-category-all .blogs-list-card > div {
    padding: 2.5rem 5rem;
  }

  .blogs-category-all .blogs-list-card > section > h1 {
    font-size: 0.8em;
    max-width: 15ch;
  }

  .blogs-category-all .blogs-list-card > section > h4 {
    font-size: 0.5em;
  }

  /* PageTag */
  .blogs-category-page-container > h3 {
    font-size: 0.7em;
  }

  /* Title and back link */
  .blogs-category-page-container > div > h1 {
    font-size: 1em;
    font-weight: 300;
  }

  .blogs-category-page-container > div > a {
    color: #4d4d4d;
    text-decoration: none;
  }
}
