.post {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.post-bottom {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: 3rem;
}

.post-container {
  padding: 3rem 8rem;
  font-family: var(--basier-circle-font);
  letter-spacing: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* HEADING */
.post-heading {
  font-size: 3em;
  padding-bottom: 1rem;
}

/* COVER IMAGE */
.post-container > img {
  padding: 3rem 0;
  height: auto;
  width: 40rem;
  text-align: center;
}

.post li{
  margin-bottom: 1rem;
}

/* MAIN CONTENT */
.post-container > summary {
  padding: 2rem;
}

/* LIKES */
.likes {
  margin-top: -1rem;
  margin-bottom: 3rem;
}

/* CONTENT */
.content-container {
  max-width: 70ch;
}

.post-bottom{
  /* margin-left: -2rem; */
}

/* --------------------Laptop-------------------- */
@media only screen and (max-width: 1024px) {
  .post-heading {
    font-size: 2.5em;
  }
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .post-container {
    padding: 3rem 1rem;
  }
}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  /* HEADING */
  .post-heading {
    font-size: 1.4em;
    text-align: center;
  }
  .post-container > img {
    padding: 1rem 0;
    width: 17rem;
  }
  /* MAIN CONTENT */
  .post-container > summary {
    font-size: 0.9em;
    padding: 2rem 1rem;
  }
  .post .blogs-list-card {
    display: none;
    padding: 3rem 5rem;
  }
  .post  .post-bottom > h2 {
    display: none;
  }
  .post-container .blogs-list-card > section > h1 {
    font-size: 0.7em;
    max-width: 20ch;
  }

  .post-bottom {
    width: 100%;
    padding-left: 1.5rem;
  }

  .likes {
    align-self: flex-start;
    justify-self: flex-start;
  }
}
