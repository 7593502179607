:root {
  --featured-background: linear-gradient(87deg, #c0c0c0 0%, #bbeaf4 100%);
  --featured-font-family: "HelveticaThin", Helvetica, sans-serif;
}

.featured {
  display: flex;
  align-items: center;
  background: var(--featured-background);
  width: 100%;
  flex-wrap: nowrap;
  /* position: relative; */
  margin: 2px 0;
}

.featured>.featured-icons>h1 {
  color: white;
  font-size: 0.9em;
  font-weight: 100;

  width: 30%;
}

.featured>h1 {
  color: white;
  font-size: 1.1em;
  text-align: center;
  width: 30%;
  color: #488993;
  font: bold;
}

.featured-icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0px 20px;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;

}

.featured>.featured-icons>img {
  display: flex;
  justify-content: flex-start;
  align-self: center;
  height: auto;
  max-width: 6rem;
  margin: 0.5rem 3rem;
}

/* .featured > .featured-icons > img:first-child,
.featured > .featured-icons > img:last-child {
  max-width: 22%; 
} */

@media only screen and (min-width: 1920px) {
  .featured-icons {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 0px 1rem;
    align-items: center;
    justify-content: flex-start;
    margin: 10px;
    margin-left: -80px;

  }

  .featured>h1 {

    font-size: 1.7rem;

  }

  .featured>.featured-icons>img {
    margin: 3.5rem 1rem;
    max-width: 9rem;
  }
}

/* --------------------Laptop-------------------- */
@media only screen and (max-width: 1024px) {
  .featured>h1 {
    font-size: 0.7em;
  }

  .featured {
    flex-direction: column;
    row-gap: 2rem;

    font-size: 1.5em;
  }

  .featured>h1 {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
  }

  .featured>.featured-icons {
    justify-content: center;
    /* column-gap: 4rem; */
    flex-wrap: wrap;
    width: 80vw;
  }

  .featured>.featured-icons>img {
    /* max-width: 15%; */
    margin: 0.5rem 1rem;
  }

  /* .featured > .featured-icons > .featured-expo-dubai {
    max-width: 12%;
  } */

  /* .featured > .featured-icons > img:first-child,
.featured > .featured-icons > img:last-child {
  max-width: 22%; 
} */

  .center {
    display: flex;
    align-items: center;
    justify-content: center;


    /* align-self: center;
    justify-self: center; */
  }
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 640px) {
  .featured>h1 {
    font-size: 0.7em;
    margin-top: 2rem;
  }

  .featured>.featured-icons>img {
    max-width: 15%;
  }

  .featured>.featured-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    margin-bottom: 2rem;
  }

  .featured {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #F3F3E8, #BCEAF4);
    width: 100%;
    flex-wrap: nowrap;
    /* position: relative; */
    margin: 2px 0;
  }

  /* .featured > .featured-icons > img:first-child,
.featured > .featured-icons > img:last-child {
  max-width: 36%; 
} */
}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .featured {
    row-gap: 1rem;
  }

  .featured>.featured-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    margin-bottom: 2rem;
  }

  .featured>.featured-icons>img {
    max-width: 14%;
    margin: 0.5rem 0.5rem;
  }

  .featured {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #F3F3E8, #BCEAF4);
    width: 100%;
    flex-wrap: nowrap;
    /* position: relative; */
    margin: 2px 0;
  }
}

/* 
.featured > .featured-icons > .featured-india{
    max-width: 70%;
}


.featured > .featured-icons > .featured-iit{
    max-width: 50%;
    margin-top: -3px;
} */

/* .featured-expo-dubai{
    grid-area: dubai;
}

.featured-iit{
    grid-area: iit;
    max-width: 10%;
}

.featured-inc{
    grid-area: inc;
}

.featured-india{
    grid-area: india;
}

.center{
    align-self: center;
    justify-self: center;
} */
/* 
@media only screen and (max-width: 900px) {
  .featured > img {
    max-width: 70%;
  }
}

@media only screen and (max-width: 640px) {
  .featured {
    padding-left: 1rem;
  }

  .featured > h1 {
    font-size: 1em;
  }

  .featured > img {
    max-width: 90%;
  }
} */