.contact-us-container{
    max-width: 100%;
    padding: 1rem 5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.contact-us-container label{
    color: #AFAFAF;
}

.contact-us-heading{
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;

}

.contact-us-form{
    width: 60%;
}

.submit-cta {
    background-color : #063B52;
    padding: 0.4rem;
    padding-left: 4rem;
    padding-right: 4rem;
    cursor: pointer;
    border-radius: 70px;
   }
   
.submit-cta-text{
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: bold;
   }

   .error-message{
    color: #C70039;
    margin-bottom: 1rem;
   }

/* --------------------Mobile and Tablets-------------------- */
@media only screen and (max-width: 680px) {

    .contact-us-container{

        padding: 1rem;
    }

}