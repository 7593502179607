.newhome-box {
  background: linear-gradient(182deg, #14484B 1.5%, #488993 98.78%);
  width: 100%;
  color: white;
  position: relative;

}

.newhome-box svg {
  position: absolute;
  bottom: 0;
}

.imgbox {

  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

}

.home-box {
  display: flex;
  width: 90%;
  margin: 10px auto;

}

.home-box1 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;


}

.home-left-box {
  margin-bottom: -6rem;
}

@media only screen and (max-width: 1024px) {
  .home-box {
    padding: 2rem;
  }

}

/* @media only screen and (max-width: 768px) {
   .home-box{
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

   }

   .newhome-box{
    background: white;
    color: black;
  }
   .home-box1{
    width: 100%;
   }
   .svgimg{
     top: -5rem;
}

  } */

  @media only screen and (max-width: 820px) {
    .home-box {
      display: flex;
      width: 90%;
      margin: 2px auto;
      flex-direction: column-reverse;
      align-items: center;
  }
  .newhome-box {
    background: white;
    color: black;
  }
  }


@media only screen and (max-width: 768px) {
  .svgimg {
    top: 4rem;
  }



  .home-box {
    display: flex;
    width: 90%;
    margin: 2px auto;
    flex-direction: column-reverse;
    align-items: center;
  }

  .newhome-box {
    background: white;
    color: black;
  }
}


@media only screen and (max-width: 425px) {
  .svgimg {
    top: 4rem;
  }

  .newhome-box {
    background: white;
    color: black;
  }
}