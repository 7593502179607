:root {
  --helvetica-thin: 'HelveticaThin', Helvetica, sans-serif;
  --footer-seperator-gradient: linear-gradient(
    177deg,
    #3bc6e4 0%,
    #22464e 100%
  );
  --footer-bottom-button-gradient: linear-gradient(
    90deg,
    #488892 0%,
    #51a4b7 100%
  );

  --footer-detail-color: #e4e4e4;
}

footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: black;
}

.footer-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 7rem;
  padding: 5rem 0rem 0;
  justify-content: center;
  margin: 0 auto;
}

.footer-main > .footer-main-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-main > .footer-main-left > img {
  height: auto;
  width: 15rem;
}

.footer-main > .footer-main-left > h2 {
  color: white;
  font-weight: 100;
  font-size: 1em;
  padding: 0.9rem 0 0 0;
}

.footer-main > .footer-main-left button {
  margin-top: 2.1rem;
}

.footer-main > .footer-main-center,
.footer-main > .footer-main-right {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  /* align-items: center; */
  /* justify-content: center; */
}

.footer-main > .footer-main-center h2,
.footer-main > .footer-main-right h2 {
  color: white;
  font-size: 1.3em;
  font-weight: 100;
}

.footer-main > .footer-main-center ul,
.footer-main > .footer-main-right ul {
  list-style-type: none;
  padding-inline-start: 0;
}

.footer-main > .footer-main-center ul li,
.footer-main > .footer-main-right ul li {
  font-size: 0.9em;
  /* font-family: 'HelveticaThin', Helvetica, sans-serif; */
  padding: 0 0 0.4rem;
  font-weight: 100;
  color: var(--footer-detail-color);
  /* text-align: center; */
}

.footer-seperator {
  background: var(--footer-seperator-gradient);
  height: 5px;
  width: 70%;
  margin: 0 auto;
}

.footer-bottom {
  padding: 1.8rem 0;
}

.footer-bottom > h1 {
  color: white;
  font-size: 0.9em;
  /* font-family: 'HelveticaThin', Helvetica, sans-serif; */
  font-weight: 100;
  text-align: center;
}

.footer-bottom-newsletter {
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

.footer-bottom > .footer-bottom-newsletter h1 {
  color: white;
  font-size: 1.2em;
  font-weight: 100;
}
/* 
.footer-bottom > .footer-bottom-newsletter > div > .footer-bottom-email-input {
  color: white;
  padding: 0.5rem 1rem;
  font-size: 0.7em;
  background-color: #595959;
  border: none;
  border-radius: 5px;
}

.footer-bottom
  > .footer-bottom-newsletter
  > div
  > .footer-bottom-email-input::placeholder {
  color: #a3a3a3;
}

.footer-bottom
  > .footer-bottom-newsletter
  > div
  > .footer-bottom-email-input:active {
  border: none;
}

.footer-bottom > .footer-bottom-newsletter > div > button {
  color: white;
  background: var(--footer-bottom-button-gradient);
  border-radius: 5px;
  font-size: 0.7em;
  letter-spacing: 0.125ch;
  font-weight: 100;
  padding: 0.5rem 1rem;
  border: none;
  margin-left: -10px;
} */

.footer-download-button {
  padding: 0.4rem 2rem;
  border-radius: 10px;
  background: var(--button-bg);
  color: white;
  font-size: 1em;
  border: none;
}

/* --------------------Laptop-------------------- */
@media only screen and (max-width: 1024px) {
  .footer-main {
    padding: 5rem 3rem 3rem;
  }

  .footer-main > .footer-main-left > div {
    margin-left: 0;
  }

  .footer-main > .footer-main-center,
  .footer-main > .footer-main-right {
    display: flex;
    flex-direction: column;
    padding-top: 0rem;
  }
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .footer-main {
    column-gap: 3rem;
    /* grid-template-columns: repeat(1,auto); */
  }

  .footer-main > .footer-main-center h2,
  .footer-main > .footer-main-right h2 {
    color: white;
    font-size: 1.1em;
    font-weight: 100;
  }

  .footer-main > .footer-main-left > img {
    height: auto;
    width: 12rem;
  }
}

/* --------------------Smaller Tablet-------------------- */
@media only screen and (max-width: 768px) {
  .footer-main {
    column-gap: 3rem;
    grid-template-columns: 1fr;
    row-gap: 4rem;
    margin: 0;
    justify-items: flex-start;
    align-items: flex-start;
  }
}



/* --------------------Mobile-------------------- */

@media only screen and (max-width: 768px) {

.footer-bottom {
  padding: 3rem 0;
}

.footer-bottom-newsletter {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

.footer-bottom > h1 {
  padding: 0 0.5rem
}
 

.footer-bottom > .footer-bottom-newsletter {
  margin-top: 0.3rem;
}

.footer-bottom > .footer-bottom-newsletter > div > .footer-bottom-email-input {
  margin-top: 1.4rem;
}

}