:root {
  --faq-arrow-color: #62a5be;
  --faq-heading-gradient: linear-gradient(
    90deg,
    #3f8fc8 0%,
    #52d5ae 58.33%,
    #4fc6bf 100%
  );
}

.faq-container {
  /* background-image: url('../../assets/background/background3.png'); */
  background-repeat: repeat-x;
  background-size: contain;
  padding: 0 5rem;
  width: 100%;
}

.faq {
  background-color: #ffffff;
  margin-top: -5px;
}

.faq-heading {
  margin-bottom: 3rem;
  padding-top: 3rem;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
}

.home .faq-heading{
  margin-top: 5rem;
}

.faq-heading > span {
  color: transparent;
  background: var(--faq-heading-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq-accordion {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.faq-accordion > details {
  font-size: 1rem;
  margin: auto;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  list-style: none;
  padding: 0 1rem 2rem;
}
svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
    
    padding-bottom: 2rem;
   
}
details > summary {
  list-style-type: none;
  position: relative;
  padding: 1rem;
  padding-bottom: 2.7rem;
  border-bottom: 1px solid black;
}

.faq-accordion > summary {
  list-style: none;
  padding: 1em;
}

.faq-summary-title {
  user-select: none;
  font-weight: bold;
  font-size: 1.8em;
}

.faq-summary-arrow {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 0;
  margin: auto 0;
  color: var(--faq-arrow-color);
  font-size: 1.7em;
}

.faq-summary-up {
  display: none;
}

.faq-accordion > details[open] .faq-summary-down {
  display: none;
}

.faq-accordion > details[open] .faq-summary-up {
  display: block;
}

.faq-summary-content {
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 100;
  font-family: inherit;
  color: #4c4c4c;
  max-width: 100ch;
}

/* --------------------Laptop-------------------- */
@media only screen and (max-width: 1024px) {
  .faq-summary-title {
    font-size: 1em;
  }
svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
    padding-bottom: 0.5rem;
}
  .faq-accordion > details {
    padding: 0 1rem 2rem;
  }

  details > summary {
    padding-bottom: 0.8rem;
  }


}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .faq-container {
    padding: 0 0;
  }

  .faq-accordion > details {
    padding: 0 4rem 2rem;
  }

  .home .faq-heading{
    margin-top: 2rem;
    padding-top: 1rem;
  }

}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 425px) {
  .faq-summary-title {
    font-size: 0.9rem;
  }

  .faq-accordion > details {
    padding: 0 2rem 0rem;
    margin-bottom: 0.5rem;
  }
  .faq-heading{
    font-size: 1.2rem;
  }
  .faq-summary-content{
    font-size: 0.8rem;
  }
  .faq-summary-arrow{
    top: 20px;
  }
  svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
    padding-bottom: 0.8rem;
}

}
