.newsletter-component > input{
  font-family: var(--basier-circle-font);
  color: white;
  padding: 0.5rem 1rem;
  font-size: 0.7em;
  background-color: #595959;
  border: none;
  border-radius: 5px;
}

.newsletter-component > input::placeholder{
  color: #a3a3a3;
}


.newsletter-component > input:active{
    border: none;
}

.newsletter-component > button{
  color: white;
  background: var(--footer-bottom-button-gradient);
  border-radius: 5px;
  font-size: 0.7em;
  font-family: var(--basier-circle-font);
  font-weight: 100;
  padding: 0.5rem 1rem;
  border: none;
  margin-left: -10px;
}