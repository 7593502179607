:root {
  --placeholder-box-color: #ee8c8c;
  --basier-circle-font: 'BasierCircle', Helvetica, sans-serif;
}

.blogs-container {
  /* background-image: url('../../assets/background/background1-2.png'); */
  background-size: cover;
  padding: 0 5rem;
  display: flex;
  justify-content: center;
}

.blogs {
  background-color: white;
  padding: 1.5rem;
  max-width: max-content;
}

/* Featured Card */
.blogs-featured {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, max-content);
  font-family: var(--basier-circle-font);
  letter-spacing: 0;
  gap: 1rem;
}

.blogs-featured-card {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  position: relative;
  width: 28rem;
  height: 14.25rem;
  z-index: 0;
}

.blogs-featured-card::before {
  content: '';
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 99%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  filter: blur(10px);
}

.blogs-featured-card > section {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 4rem 0 1rem 1rem;
  z-index: 1;
}

.blogs-featured-card > section > h4 {
  font-size: 0.6em;
  font-weight: 500;
}

.blogs-featured-card > section > h1 {
  font-weight: 300;
  font-size: 1.1em;
  max-width: 35ch;
}

.blogs-featured-card-tag {
  background-color: white;
  max-width: max-content;
  color: black;
  margin-top: 1rem;
  padding: 4px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 0.7em;
}

/* CATEGORY CARD */

.blogs-category-section-heading {
  margin: 2rem 0;
  font-weight: 300;
  font-size: 1.4em;
}

.blogs-category-section-heading,
.blogs-category-section {
  letter-spacing: 0;
}

.blogs-category-section {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  gap: 1rem;
}

.blogs-category-section-card {
  background-color: var(--placeholder-box-color);
  padding: 7rem 4rem 0.5rem 0.5rem;
  border-radius: 9px;
}

.blogs-category-section-card-link {
  color: black;
  font-family: var(--basier-circle-font);
  text-decoration: none;
}

.blogs-category-section-card > h1 {
  font-size: 1em;
  background-color: white;
  font-weight: 400;
  padding: 5px 7px;
  border-radius: 5px;
  max-width: max-content;
  text-align: left;
}

/* RECENT BLOGS */

.blogs-recent {
}

.blogs-recent > h1 {
  font-size: 1.4em;
  font-weight: 300;
  margin: 2rem 0;
}

/* List item for the recent blogs */

.blogs-list-card {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 1.5rem;
  margin-top: 1.5rem;
}

/* Placeholder recent image */
.blogs-list-card > div {
  padding: 3.5rem 7rem;
  background-color: var(--placeholder-box-color);
  border-radius: 5px;
}

/* Placeholder text box */
.blogs-list-card > section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: black;
  text-decoration: none;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.blogs-list-card > section > h4 {
  font-size: 0.6em;
  font-weight: 500;
}

.blogs-list-card > section > h1 {
  font-weight: 300;
  font-size: 1.1em;
}

/* --------------------Laptop-------------------- */

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1024px) {
  /* FEATURED  */
  .blogs-featured-card {
    padding-right: 0.8rem;
  }

  .blogs-featured-card > section > h4 {
    font-size: 0.6em;
  }

  .blogs-featured-card > section > h1 {
    font-size: 0.9em;
  }

  /* RECENT BLOGS  */

  .blogs-recent {
  }

  .blogs-recent > h1 {
  }
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .blogs-container {
    padding: 0;
  }

  /* FEATURED */
  .blogs-featured {
    grid-template-columns: max-content;
    display: none;
  }

  /* CATEGORY */
  .blogs-category-section {
    grid-template-columns: repeat(2, 1fr);
  }
  .blogs-category-section-heading {
    margin-top: 0;
    text-align: center;
  }

  /* RECENT BLOGS  */

  .blogs-recent {
    display: none;
  }
}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .blogs-category-section-card {
    padding-right: 1rem;
  }

  .blogs-category-section-card > h1 {
    font-weight: 300;
    font-size: 0.58em;
    text-align: left;
    max-width: max-content;
  }
}

@media only screen and (max-width: 380px) {
  .blogs-category-section {
    grid-template-columns: max-content;
  }
}
