.post-infoBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  gap: 5rem;
}

/* LEFT SIDE WITH THE AUTHOR */
.post-infoBar-author {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.post-infoBar-author > div {
  padding-left: 1rem;
  border-radius: 50%;
}

.post-infoBar-author > section > h4 {
  color: #5e5e5e;
  font-size: 0.8em;
  font-weight: 500;
}

/* SHARE SECTION */
.post-infoBar-share {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

/* SHARE BUTTON */

.post-infoBar-share > button {
  background-color: black;
  color: white;
  font-size: 1em;
  font-weight: 100;
  padding: 0.3rem 3rem;
  border-radius: 20px;
}

.author-profile-pic{
  font-size: 3rem;
  color:#0D303F ;
}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .post-infoBar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    align-self: flex-start;
    gap: 1rem;
  }

  .post-infoBar-author > section > h3 {
    font-size: 1em;
  }

  .post-infoBar-share > button {
    padding: 0.3rem 2rem;
    font-size: 0.8em;
  }

  .post-infoBar-author > div {
    padding: 1.5rem;
  }

  .post-infoBar-share {
    gap: 1rem;
  }
  /* IMAGES OF SHARE */
  .post-infoBar-share > a > button > img{
    height: auto;
    width: 20px;
  }
}
