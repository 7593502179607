:root {
  --blue-gradient: linear-gradient(180deg, #cfdaf6 0%, #eadfe9 100%);
  --peach-gradient: linear-gradient(180deg, #ffe2dc 0%, #fad8b0 100%);
  --dark-blue: #346aaa;
}

.aboutUs-container {
  display: grid;
  font-family: var(--basier-circle-font);
  letter-spacing: 0;
  grid-template-areas:
    "blueGradient headerImg headerImg"
    "blueGradient ourStory peachGradient"
    "team team peachGradient";
}

.gradient {
  grid-area: blueGradient;
  background: var(--blue-gradient);

  width: 20vw;
}

.headerImg {
  grid-area: headerImg;
  height: auto;
  width: 80vw;
}

.aboutUs-container > .aboutUs-story {
  grid-area: ourStory;
  padding: 2rem;
}

.aboutUs-container > .aboutUs-story > h1 {
  font-size: 1.3em;
}

.aboutUs-container > .aboutUs-story > div {
  font-size: 1em;
  padding: 2rem 0 0;
}

.peach-gradient {
  grid-area: peachGradient;
  background: var(--peach-gradient);
  width: 20vw;
}

.team {
  grid-area: team;
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  padding-left: 20vw;
}

.team > h1 {
  font-weight: 400;
  text-decoration: underline;
  padding: 2rem 0;
}

.aboutUs-team-card {
  display: flex;
  align-items: center;
  gap: 5rem;
  margin: 2rem;
  justify-content: space-between;
}

.team-info > .team-card-pfp {
  
  border-radius: 50%;

}

.team-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-info > h2 {
  padding-top: 1rem;
  font-size: 1.1em;
}

.team-info > h3 {
  font-size: 1em;
  font-weight: 100;
}
.team-description {
  font-weight: 200;
  font-size: 1rem;
  max-width: 60ch;
}

.profile-image{
  width: 15vw;
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 768px) {
  .aboutUs-container {
    display: grid;
    font-family: var(--basier-circle-font);
    letter-spacing: 0;
    grid-template-areas:
      "headerImg"
      "ourStory"
      "team";
  }
  .headerImg {
    grid-area: headerImg;
    height: 9rem;
    width: 100vw;
  }



}



/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .team {
    padding: 1rem;
  }

  .team > h1 {
    font-weight: 400;
    text-decoration: underline;
    padding: 2rem 0;
    font-size: 1.4em;
  }
  .aboutUs-team-card {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .team-description {
    font-size: 1rem;
  }

  .profile-image{
    width: 50vw;
  }
}
