:root {
  --header-linear-gradient: linear-gradient(
    90deg,
    #467d80 0%,
    #154a4d 40.63%,
    #0a3d40 100%
  );
  --button-bg: linear-gradient(90deg, #2b95ab 0%, #419dbb 58.33%, #4fc6bf 100%);
  --nav-bottom-gradient: linear-gradient(
    90.08deg,
    #4aa1b4 3.4%,
    #3f71bd 97.72%
  );
}

nav > a {
  color: white;
  text-decoration: none;
  font-weight: 100;
  font-size: 0.9em;
  -webkit-tap-highlight-color: transparent;
}

.logo {
  grid-area: logo;
  height: auto;
  width: 10rem;
}

.header {
  background: var(--header-linear-gradient);
  display: grid;
  grid-template-areas: 'logo nav';
  align-items: center;
  padding: 1rem 4rem;
}

.nav {
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(6, max-content);
  gap: 2rem;
  align-items: center;
  justify-items: center;
  justify-self: end;
}

.download-button {
  padding: 0.4rem 2rem;
  border-radius: 10px;
  background: var(--button-bg);
  color: white;
  font-size: 1em;
  border: none;
}

button:active {
  opacity: 40%;
}

.menu-button-outer{
  display: none;
}

.menu-button {
  grid-area: menu;
  display: none;
  color: white;
  height: auto;
  width: 2.5rem;
  -webkit-tap-highlight-color: transparent;
}

.menu-button:active {
  color: lightgray;
}

.gradient-line {
  position: relative;
}

.gradient-line.active::after{
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 30px;
  height: 5px;
  background: var(--nav-bottom-gradient);
  border-radius: 2px;
}

.header a:hover{
  color: #FFFFFF;
  text-decoration: none;
}

.display {
  display: grid;
}

/* --------------------Laptop-------------------- */
@media only screen and (max-width: 1024px) {
  .header {
    padding: 1rem 1rem;
  }
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .header {
    grid-template-areas:
      'menu logo'
      'nav nav';
    justify-items: flex-start;
  }

  .logo {
    margin-right: 2.5rem;
  }

.menu-button-outer{
  display: block;
}

  .menu-button {
    display: block;
    user-select: none;
    align-self: center;
    justify-self: flex-end;
  }

  .header > button {
    user-select: none; /* Prevents the button from being highlighted */
    -webkit-tap-highlight-color: transparent;
  }

  .nav {
    position: absolute;
    left: 0;
    top: 6.5rem;
    border-radius: 17px;
    z-index: 100;
    padding: 3rem;
    grid-template-rows: repeat(5, auto);
    grid-template-columns: none;
    grid-row-gap: 30px;
    background: rgba(0, 0, 0, 1);
  }

  .hide {
    display: none;
  }
}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .menu-button {
    font-size: 1.3em;
    width: 1.75rem;
  }

  .logo {
    margin-right: 1rem;
  }
  .nav {
    position: absolute;
    font-size: 0.6em;
    left: 0;
    top: 6.5rem;
    border-radius: 17px;
    z-index: 100;
    padding: 1.5rem;
    grid-template-rows: repeat(5, auto);
    grid-template-columns: none;
    grid-row-gap: 30px;
    background: rgba(0, 0, 0, 1);
  }
}
