:root{
    --quickLinks-background-gradient: linear-gradient(84deg, #507A7A 0%, #488891 53.65%, #3F96AA 100%);
}

.quickLink-container{
    background-color: black;
    width: 100%;
}

.quickLinks{
    background: var(--quickLinks-background-gradient);
    padding: 1rem 0rem 3rem;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.quickLinks h1{
    color: white;
    text-align: center;
    font-weight: 200;
    font-size: 1.3em;
    padding-bottom: 3rem;
}

.quickLinks > div{
    display: grid;
    grid-template-columns: repeat(6,auto);
    justify-items: center;
    align-items:center ;
    justify-content: center;
    margin: 0 auto;
}

.quickLinks > div > a > img{
    display: flex;
    justify-content: center;
    height: auto;
    max-width: 30%;
    align-self:center;
    margin: 0 auto;
}

@media only screen and (max-width: 640px){
.quickLinks > div > a > img{
    max-width: 45%;
}

.quickLinks h1{
    color: white;
    text-align: center;
    font-weight: 200;
    font-size: 1em;
    padding-bottom: 3rem;
}

}