/* @import url("https://fonts.cdnfonts.com/css/microsoft-sans-serif"); */
:root {
  --information-background: linear-gradient(91deg, #fff 0%, #fcfeff 100%);
  /* --microsoft-sans-serif: "Microsoft Sans Serif", sans-serif; */
}

.information-container {
  /* background-image: url("../../assets/background/background2.png"); */
  background-repeat: repeat-x;
  background-size: contain;
  padding: 0 5rem;
  letter-spacing: 0;
}
 
/* --------------------DESKTOPDESIGN PART------------------------------ */

.information-container > .desktop{
  background-color: white;
  padding: 3rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.information-container > .desktop > .box{
  display:  flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: -4rem;
}

.textBox > h1 {
  font-size: 2rem;
  font-weight: 500;
}


.leftBox{
  align-self: flex-start;
 
}

.rightBox{
  align-self: flex-end;
}

/* .information-container > .desktop > .box h1{
  font-size: 1.3em;
} */

.information-container > .desktop > .box summary{
  font-size: 1.2em;
  padding-top: 2rem;
  max-width: 65ch;
} 

.information-container > .desktop  img{
  height: auto;
  width: 55%;
  aspect-ratio: 1;
}

.information {
  background: var(--information-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
}

.main-info {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: fit-content;
}

.main-info > .rectangleImg {
  margin-top: 2rem;
  height: auto;
  width: 70rem;
}

.main-info > .rectanglePhone {
  position: absolute;
  right: 2rem;
  top: 5rem;
  height: auto;
  width: 27rem;
}

.main-descriptionOne {
  position: absolute;
  font-weight: 500;
  font-size: 2em;
  max-width: 28ch;
  left: 3rem;
  transform: translateX(-85%);
  top: 50%;
  transform: translateY(-50%);
}

.descriptionTwo {
  position: absolute;
  bottom: -13rem;
  left: 3rem;
  font-weight: 500;
  font-size: 2em;
  max-width: 25ch;
}

/* BLOG PROMOTION */
.info-blog-card {
  border: 2px solid #e1e1e1;
  margin-top: 21rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}


.info-blog-card > h1 {
  font-weight: 300;
  font-size: 1.5em;
  padding: 0 4rem;
}

.information-description {
  font-weight: 500;
  max-width: 60ch;
}

.information-box:nth-child(1) {
  margin-top: 1rem;
}

.information-box:nth-child(2) {
  margin-top: 5rem;
}

.information-imgOne {
  grid-area: image;
  height: auto;
  width: 50%;
}

.information-text-wrapper {
  grid-area: text;
  align-self: center;
}

.information-imgOnLeft {
  display: grid;
  grid-template-areas: "image text";
}

.information-imgOnRight {
  display: grid;
  grid-template-areas: "text image";
  justify-items: end;
}

.info-blog-image{
  width: 40vw !important;
  border-radius: 15px;
}

/* --------------------Laptop-------------------- */
@media only screen and (max-width: 1024px) {
  .main-info > .rectangleImg {
    margin-top: 2rem;
    height: auto;
    width: 40rem;
  }

  .main-info > .rectanglePhone {
    position: absolute;
    top: 5rem;
    height: auto;
    width: 15rem;
  }
.leftBox{
  margin-top: 10rem;
  margin-bottom: 50px;

}
.left-box-img{
  height: 50%;
  width: auto;
}
  .main-descriptionOne {
    font-size: 1.3em;
    max-width: 28ch;
  }

  .descriptionTwo {
    font-size: 1.3em;
    bottom: -8rem;
  }

  /* BLOG PROMOTION */
  .info-blog-card {
    margin-top: 11rem;
  }



  .info-blog-card > h1 {
    font-size: 1.5em;
    padding: 0 2rem;
  }
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .information {
    display: flex;
  }
/* .information-container > .desktop{
  display: none;
} */
  .information-container {
    padding: 0 0rem;
  }

  .main-info > .rectangleImg {
    margin-top: 2rem;
    height: auto;
    width: 40rem;
  }

  .main-info > .rectanglePhone {
    position: absolute;
    top: 5rem;
    height: auto;
    width: 15rem;
  }

  .main-descriptionOne {
    position: absolute;
    font-weight: 500;
    font-size: 1.2em;
    max-width: 28ch;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .descriptionTwo {
    font-size: 1.2em;
    left: 1rem;
    max-width: 28ch;
  }
}


/* --------------------Mobile-------------------- */
@media only screen and (max-width: 768px) {

  .main-info > .rectangleImg {
    margin-top: 2rem;
    height: auto;
    width: 20rem;
  }

  .main-info > .rectanglePhone {
    position: absolute;
    right: 1rem;
    top: 5rem;
    height: auto;
    width: 8.75rem;
  }

  .main-descriptionOne {
    position: absolute;
    font-weight: 500;
    font-size: 0.65em;
    max-width: 28ch;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .descriptionTwo {
    bottom: -4rem;
    font-size: 0.65em;
    left: 1rem;
    max-width: 28ch;
  }

  /* BLOG PROMOTION */
  .info-blog-card {
    border-radius: 10px;
    padding: 1rem;
  }


  .info-blog-card > h1 {
    font-size: 1em;
    padding: 0 1rem;
  }

  .box{
    margin-top: 1rem !important;
    flex-direction: column;
  }

  .information-container > .desktop > .box summary{
    font-size: 1em;
    padding-top: 1rem;
    text-align: center;
  } 
  
  .information-container > .desktop  img{
    height: auto;
    width: 100%;
    aspect-ratio: 1;
  }

  .rightBox{

    flex-direction: column-reverse;
  }

  .textBox > h1 {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }

  .rightBox .textBox{
    margin-top: 1rem !important;
  }

  .info-blog-card {
    margin-top: 2rem;
  }

  .desktop{
    padding: 1rem !important;
  }

}
