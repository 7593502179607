.ei-container{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    margin-top:2rem;
}

.ei-container-text{
    text-align: center;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
}
.ei-container-text-heading{
    font-size: 1.5rem;
}
.ei-main-section{

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.ei-image{

    width: 100%;
    aspect-ratio: 1.85;

}

.MuiSlider-mark{

    display: none;

}

.ei-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 1rem;
    padding: 1.5rem;
    border: solid #5890FE;
    border-width: 2px;
    background-color: #EBF2FF;
    border-radius: 30px;
    width: 25%;
}

.m-space{
    margin-top: 2rem;
}

.ei-content-number{

    font-size: 2rem;
    color:#153A56;
    margin-bottom: 0.5rem;
}

.ei-image-container{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    width:55%;
}

.ei-image-container-text{
    align-self: flex-end;
    color: #2EB077;
    font-size: 1.5rem;
}

/* --------------------Mobile and Tablets-------------------- */
@media only screen and (max-width: 680px) {

    .ei-main-section{
        flex-direction: column;
        text-align: center;
    }

    .ei-image-container-text{
        color: #2EB077;
        font-size: 1.5rem;
        align-self: center;
    }

    .ei-image-container{
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        width:100%;
    }

    .ei-image{

        margin-top: 1rem;
        margin-bottom: 1rem;
    
    }


}

@media only screen and (max-width: 1024px){
    .ei-content{
       width: 35%;
    }
}

@media only screen and (max-width: 768px){
    .ei-content{
       padding: 1rem;
    }
}

@media only screen and (max-width: 425px){
    .ei-content{
       width: 60%;
       margin: 1.5rem;
    }
}

@media only screen and (max-width: 320px){
    .ei-content{
       width: 80%;
    }
}