.new-featured-page {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.new-featured-page-box {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-top: -5rem;
  margin-bottom: 1rem;
}
.reverse {
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  margin-top: 1rem;
}
.new-featured-page-box2 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  margin: 2rem;
}

.new-featured-page-box1 {
  width: 30%;
  display: flex;
  align-items: flex-end;

  flex-direction: column;
  margin: 2rem;
  text-align: right;
}

.new-featured-page-box1 img {
  width: 100%;
  height: auto;
}

.new-featured-page-box1 button {
  border-radius: 2rem;
  border: 2px solid #fff;
  background: #021418;
  color: #fff;
  padding: 0.5rem 2rem;
  width: 80%;
  margin-bottom: 2rem;
}
.featured-text1 {
  color: #000;

  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.featured-text2 {
  color: #000;

  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.new-featured-page-bottombox {
  width: 100%;
  background: linear-gradient(90deg, #f5ddc5 0%, #fccaca 48.5%, #f6f4e8 100%);
  padding: 1rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottombox-left {
  width: 80%;
  color: #ac5959;
  font-style: normal;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.left-bottombox-textbox {
  margin-left: 1rem;
}
.bottombox-text {
  font-size: 1.5rem;
  margin-top: 1rem;
}
.bottombox-right {
  margin-left: 5rem;
  width: 20%;
}
.bottombox-right button {
  color: #fff;
  text-align: center;
  border-radius: 69.5px;
  background: #000;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.left-bottombox-textbox button {
  display: none;
}
.new-featured-page-mobile {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .new-featured-page-box {
    margin-top: 0;
  }
  .bottombox-left {
    font-size: 1rem;
  }
  .bottombox-right {
    margin-left: 1rem;
    width: 25%;
  }
  .bottombox-right button {
    width: fit-content;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 425px) {
  .new-featured-page-mobile-box1-textbox {
    width: 60% !important;
    padding: 1rem 1rem;
    align-items: center;
  }
  .new-featured-page-bottombox {
    padding: 1rem;
    border-radius: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    width: 90%;
  }
  .bottombox-left {
    width: 100%;
  }
  .bottombox-text {
    font-size: 0.8rem;
    margin: 0;
  }
  .left-bottombox-textbox {
    width: 100%;
  }
  .bottombox-text2 {
    font-size: 0.6rem;
    margin: 0;
    margin-top: 0.5rem;
  }
  .bottombox-right button {
    color: #fff;
    font-size: 0.8rem;
    padding: 0.3rem 1rem;
    width: fit-content;
  }
  .questionmark {
    width: 5rem;
    height: 5rem;
  }
  .bottombox-right {
    display: none;
  }
  .left-bottombox-textbox button {
    display: flex;
    color: #fff;
    font-size: 0.7rem;
    padding: 0.3rem 1rem;
    width: fit-content;
    background-color: #000;
    border-radius: 10rem;
    margin-top: 1rem;
  }
  .new-featured-page-box {
    width: 100%;
  }
}
@media only screen and (max-width: 370px) {
  .new-featured-page-mobile-box1-textbox {
    width: 40% !important;
  }
  .mobile-img{
    /* height: 25%; */
  }
  .new-featured-page-mobile-box1-textbox{
    font-size: 0.7rem !important;
    padding: 1rem 0.5rem !important;
  }
  .new-featured-page-mobile{
    padding: 0.5rem !important;
  }
 
}
@media only screen and (max-width: 768px) {
  .new-featured-page-box {
    display: none;
  }
  .new-featured-page-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
  }
  .new-featured-page-mobile-box1 {
    display: flex;
   
    align-items: center; 
    width: 100%;
    color: #000;
    text-align: right;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .new-featured-page-mobile-box1-textbox {
    display: flex;
    width: 70%;
    padding: 2rem 1rem;
    align-items: center;
    font-size: 1rem;
   
  }
  .textbox2 {
    text-align: left;
  }

  .mobile-img {
    width: 50%;
  }
}

@media only screen and (max-width: 786px) {
  .new-featured-page-box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .new-featured-page-box2 {
    width: 70%;
    align-items: center;
    justify-content: center;
  }
  .new-featured-page-box1 img {
    width: 100%;
    height: auto;
  }
  .new-featured-page-box1 {
    width: 70%;

    align-items: center;

    /* justify-content: center; */
    text-align: left;
  }
}
