:root {
  --hero-playStore-gradient: linear-gradient(
    90deg,
    #83D176 0%,
    #64BC5C 54.69%,
    #42A062 100%
  );

  --hero-appStore-gradient: linear-gradient(
    90deg,
    #3fb5ce 0%,
    #5ca8c1 58.33%,
    #1aa098 100%
  );
  --hero-background: #FFFFFF;
}

.hero-for-mobile{
  display: none;
}

.hero-container {
  /* background-image: url("../../assets/background/background1.png"); */
  /* background-repeat: repeat-x;
  background-size: contain; */
  padding: 0 5rem;
}

.hero {
  background-color: var(--hero-background);
  position: relative;
  display: grid;
  grid-template-areas: "text image";
  justify-items: center;
  align-items: center;
}

.hero-text {
  grid-area: text;
}



.hero-text > h1 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.hero-text > h4 {
  margin: 2rem 0;
}

/* Logos */
.hero-playStore--logo,
.hero-appStore--logo {
  height: auto;
  max-width: 15%;
}

.hero-playStore--logo {
  max-width: 13%;
}

/* Main Button Styling */
.hero-playStore--button,
.hero-appStore--button {
  display: flex;
  justify-content: center;
  column-gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-family: inherit;
  color: white;
  font-size: large;
  border-radius: 30px;
}

/* Button Gradients */

.hero-playStore--button,
.hero-appStore--button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-playStore--button {
  background: var(--hero-playStore-gradient);
}

.hero-appStore--button {
  background: var(--hero-appStore-gradient);
}

.hero-appStore--logo {
  margin-top: -3px;
}

.hero-button--wrapper {
  display: flex;
  column-gap: 1.5rem;
  margin-top: 3rem;
  align-items: center;
  flex-direction: column;
}

.hero-button--wrapper p{
  display: none;
  font-weight: bold;
}

.button-box-wrapper{
  display: flex;
  gap: 20px;
}

.hero-text--description {
  max-width: 50ch;
  font-weight: 600;
  margin-top: 3rem;
  font-size: 1rem;
}

/* Image Section */

.home-hero-image {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  
}
.home-hero-image--main {
  grid-area: image;
  height: auto;
  width: 40vw;
  top: 0;
  right: 10px;
  justify-self: center;
  align-self: center;
  display: flex;
}
.home-hero-image--main-mobile{
  display: none;
   grid-area: image;
  height: auto;
  width: 60vw;
  top: 0;
  right: 10px;
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
 
}
.absolute {
  position: absolute;
}

.hero-button--wrapper > a {
  text-decoration: none;
}

summary {
  display: block;
}

.hero-text--heading{
  font-size: 2rem;
}
.find-therapist-btn{
  color: #468C98;
font-size: 1.4rem;
font-style: normal;
font-weight: bold;
line-height: normal;
padding: 1rem 2rem;
border-radius: 5rem;
border: 2px solid #FFF;
background: #FFF;
margin-top: 2rem;
}
.btnwraper-div{
  flex-direction: column;
}
.btn-wrapper{
  display: flex;
  gap: 2rem;
}
.explore-text{
  color: #7F8381;
margin-bottom: 0.5rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}



.home-hero-image--main {
  display: block;
}

.home-hero-image--main-mobile {
  display: none;
}
/* --------------------Laptop-------------------- */

@media only screen and (max-width: 1920px) {

  .hero-text--description {
    font-size: 1em;
  }

  .hero-text {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 1rem;
}
}

@media only screen and (max-width: 1024px) {
  .hero-container {
    padding: 0 5rem;
  }

  .hero-text > h1 {
    font-size: 1.4rem;
}

  .hero-text {
    padding-left: 1rem;
  }

  .hero-text--description {
    font-size: 1rem;
  }

  .home-hero-image {
    display: flex;
    align-items: center;
   
  }

  .home-hero-image--main{
    width: 20rem;
 
  }

  .hero-playStore--button,
  .hero-appStore--button {
    padding: 0.5rem 1rem;
    font-size: 0.8em;
  }
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .hero-container {
    padding: 0 0rem;
  }
.home-hero-image--main-mobile{
  display: flex;
}
.home-hero-image--main{
   display: none;
}
  .hero {
    grid-template-areas: "image" "text";
  }

  .home-hero-image--main-mobile{
  
    margin-bottom: 0px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
  }
  .hero-text--description {
    margin-top: 1.5rem;
    font-size: 1em;
}
  .hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 3rem 3rem;
    text-align: center;
  }

  .hero-text--description {
    margin-top: 1.5rem;
    font-size: 1em;
    width: 70vw;
  }



  /* Image */
  .home-hero-image {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .hero-image--main {
    position: static;
    max-width: 100%;
  }

  .hero-button--wrapper {
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .for-desktop{
display: none;
  }

  .hero-text > h1 {
    font-size: 1.3rem;
}

}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .hero-text {
    margin: 0 auto;
   
  }

  .hero-text--heading {
    font-size: 1.5rem;
  }

  .home-hero-image--main {
    display: none;
  }

  .home-hero-image--main-mobile {
    display: block;
  }


  .hero-text > h1 {
    font-size: 1.2rem;
}
  .hero-text > h4 {
    text-align: center;
  }

  .hero-text--description {
    margin-top: 1rem;
    font-size: 0.9em;
    text-align: center;
  }
  /* Main Button Styling */

  .hero-playStore--button,
  .hero-appStore--button {
    font-size: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

      .hero-sub-section-card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 0.2rem;
    }
}



@media only screen and (max-width: 425px){
  .hero-button--wrapper p{
    display: flex;
  }
  
  } 

@media only screen and (max-width: 375px){
.hero-text > h1 {
    font-size: 1.2rem;
}
} 
