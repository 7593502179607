.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #5DE3A8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circle span {
  color: #fff;
  text-align: center;
  font-size: 25px;
}
  
.timeline-container {
width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0D303F;
  padding: 1rem;
  padding: 50px;
}

.sub-container {
  width: 90%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-evenly
}

@media (max-width: 600px) {
  /* Vertical alignment for mobile */
  .container {
    flex-direction: column;
  }

  .sub-container {
    flex-direction:column;
  }

  .connector {
    width: 4px;
  }

  .horizontal-connector {
    display: none;
  }
}