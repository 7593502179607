:root {

  --hero-background: #FFFFFF;
}

.corporate-hero-cta {
 background-color : #063B52;
 padding: 1rem;
 padding-left: 4rem;
 padding-right: 4rem;
 cursor: pointer;
 border-radius: 70px;
}

.corporate-hero-cta-text {
  color: #FFFFFF;
  font-weight: bold;
}

.hero-container {
  /* background-image: url("../../assets/background/background1.png"); */
  /* background-repeat: repeat-x;
  background-size: contain; */
  padding: 0 5rem;
}

.hero {
  background-color: var(--hero-background);
  position: relative;
  display: grid;
  grid-template-areas: "text image";
  justify-items: center;
  align-items: center;
}

.hero-text {
  grid-area: text;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;
  }



.hero-text > h1 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.hero-text > h4 {
  margin: 2rem 0;
}

/* Logos */
.hero-playStore--logo,
.hero-appStore--logo {
  height: auto;
  max-width: 15%;
}

.hero-playStore--logo {
  max-width: 13%;
}

/* Main Button Styling */
.hero-playStore--button,
.hero-appStore--button {
  display: flex;
  justify-content: center;
  column-gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-family: inherit;
  color: white;
  font-size: large;
  border-radius: 30px;
}

/* Button Gradients */

.hero-playStore--button,
.hero-appStore--button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-playStore--button {
  background: var(--hero-playStore-gradient);
}

.hero-appStore--button {
  background: var(--hero-appStore-gradient);
}

.hero-appStore--logo {
  margin-top: -3px;
}

.hero-button--wrapper {
  display: flex;
  column-gap: 1.5rem;
  margin-top: 3rem;
}

.hero-text--description {
  max-width: 50ch;
  margin-top: 2rem;
  font-size: 1.1em;
  font-weight: 400;
}

/* Image Section */

.hero-image {
  padding: 5rem;
}
.hero-image--main {
  grid-area: image;
  height: auto;
  width: 20rem;
  top: 0;
  right: 10px;
  justify-self: center;
  align-self: center;
}

.absolute {
  position: absolute;
}

.hero-button--wrapper > a {
  text-decoration: none;
}

.hero-sub-section {

  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.hero-sub-section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
}

.hero-sub-section-card-container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates four columns of equal width */
  gap: 10px;
  
}
.hero-sub-section-card{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  min-width: 25%;
}
/* --------------------Laptop-------------------- */

@media only screen and (max-width: 1920px) {
  /* heading */
  .hero-text {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .hero-text--description {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .hero-container {
    padding: 0 5rem;
  }

  .hero-text {
    padding-left: 1rem;
  }


  .hero-text--description {
    font-size: 1rem;
  }

  .hero-image {
    display: flex;
    align-items: center;
    padding: 3rem;
  }

  .hero-image--main {
    width: 15rem;
  }

  .hero-playStore--button,
  .hero-appStore--button {
    padding: 0.5rem 1rem;
    font-size: 0.8em;
  }
}

/* --------------------Tablet-------------------- */
@media only screen and (max-width: 820px) {
  .hero-container {
    padding: 0 0rem;
  }

  .hero {
    grid-template-areas: "image" "text";
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 3rem 3rem;
    text-align: center;
  }

  .hero-text--description {
    margin-top: 1.5rem;
    font-size: 1em;
   
  }

.hero-text-container{
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
  /* Image */
  .hero-image {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .hero-image--main {
    position: static;
    max-width: 100%;
  }

  .hero-button--wrapper {
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .hero-playStore--button,
  .hero-appStore--button {
    padding: 0.4rem 0.5rem;
    font-size: 0.8em;
  }

   .hero-sub-section-card-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

}

/* --------------------Mobile-------------------- */
@media only screen and (max-width: 480px) {
  .hero-text {
    margin: 0 auto;
    padding: 0 1rem 1rem;
  }


  .hero-text > h4 {
    text-align: center;
  }

  .hero-text--description {
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
  }
  /* Main Button Styling */

  .hero-playStore--button,
  .hero-appStore--button {
    font-size: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-sub-section-card-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .hero-playStore--button,
  .hero-appStore--button {
    padding: 0.4rem 0.2rem;
    font-size: 0.8em;
  }
}
