@font-face {
  font-family: 'HelveticaMedium';
  src: local('HelveticaMedium'),
    url('./assets/fonts/helvetica-lt-pro/helvetica-medium.otf')
      format('opentype');
}

@font-face {
  font-family: 'HelveticaRoman';
  src: local('HelveticaRoman'),
    url('./assets/fonts/helvetica-lt-pro/helvetica-roman.otf')
      format('opentype');
}

@font-face {
  font-family: 'HelveticaThin';
  src: local('HelveticaThin'),
    url('./assets/fonts/helvetica-lt-pro/helvetica-thin.otf') format('opentype');
}

@font-face {
  font-family: 'BasierCircle';
  src: local('BasierCircle'),
    url('./assets/fonts/basier-circle/BasierCircle-Regular.ttf') format('truetype');
}

:root {
  --font-family: 'BasierCircle', Helvetica, sans-serif;
  --banner-gradient: linear-gradient(90deg, #BDCCF5 0%, #EDD0E8 100%);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* letter-spacing: 0.1ch; */

}

h2,h3,h4{
  margin-block-end: 0;
  margin-block-start: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  margin-block-start: 0;
  margin-block-end: 0;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

.top-banner{
  background: var(--banner-gradient);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  padding: 0.2rem 0;
}

.top-banner > a{
  text-decoration: none;
  color: black;
}

.redirect {
  display: flex;
  overflow: hidden;
  margin-bottom: 0;
}

.redirect-img-One {
  position: relative;
}

.redirect-imgOne-text {
  position: absolute;
  top: 0;
}

.redirect-imgOne-bottomText {
  bottom: 0;
  left: 8rem;
}

.redirect-text {
  position: absolute;
  color: white;
  left: 50%;
  transform: translateX(-50%);
}

.redirect > div > img {
  height: auto;
  opacity: 70%;
  width: 100%;
  object-fit: fill;
}

.section-content{
  margin-top: 1rem;
}

@media only screen and (max-width: 580px){
  .redirect-text{
    font-size: .9em;
  }
  .section-title{
    margin-top: 1.5rem;
  }
}