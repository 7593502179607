.home{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.floating-wp-btn{
position: fixed;
bottom: 3rem;
right: 4.3rem;
border-radius: 93px;
background: #000;
padding: 0.5rem;
color: #FFF;
font-size: 1.4rem;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: center;
padding-left: 1rem;
z-index: 9999;
display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.wp-img{

}
@media only screen and (max-width: 425px){
   .floating-wp-btn{
    bottom: 3rem;
right: 1.3rem;
   } 
}